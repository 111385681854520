<template>
  <div>
    <!-- <pre>{{ positionList }}</pre> -->
    <div v-if="imgUrl">
      <b-row cols="1" cols-lg="2">
        <b-col>
          <div v-for="(position, index) in positionList" :key="position.key" class="container-input-editor">
            <h5>{{ position.hint }}</h5>
            <div class="group-input">
              <label for="positionY" class="form-position">
                <p>ตำแหน่ง{{ position.hint }}แกน Y</p>
                <input
                  type="number"
                  name="positionY"
                  class="form-control"
                  v-model="position.positionY"
                  :disabled="globalData.currentActive !== index"
                  @input="onChangeInputPosition($event, position)"
                />
              </label>
              <label for="positionX" class="form-position">
                <p>ตำแหน่ง{{ position.hint }}แกน X</p>
                <input
                  type="number"
                  name="positionX"
                  class="form-control"
                  v-model="position.positionX"
                  :disabled="globalData.currentActive !== index"
                  @input="onChangeInputPosition($event, position)"
                />
              </label>
              <label for="boxWidth" class="form-position">
                <p>ความกว้างกล่อง</p>
                <input
                  type="number"
                  name="boxWidth"
                  class="form-control"
                  v-model="position.offsetBoxWidth"
                  :disabled="globalData.currentActive !== index"
                  @input="onChangeInputBox($event, position)"
                />
              </label>
              <label for="boxHeight" class="form-position">
                <p>ความสูงกล่อง</p>
                <input
                  type="number"
                  name="boxHeight"
                  class="form-control"
                  v-model="position.offsetBoxHeight"
                  :disabled="globalData.currentActive !== index"
                  @input="onChangeInputBox($event, position)"
                />
              </label>
              <BButton variant="primary" class="custom-btn" @click="onClickEdit(position)"
                >{{ renderTextEdit(index) }} {{ position.hint }}</BButton
              >
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="box" @click="savePosition">
            <img
              :src="imgUrl"
              alt="test"
              class="image"
              ref="editorImage"
              @load="onImageLoad"
              @click="calculatePosition"
            />
            <div v-for="position in positionList" :key="position.key">
              <EditorMarker
                :markerTop="position.markerTop"
                :markerLeft="position.markerLeft"
                :hint="position.hint"
                :boxWidth="position.boxWidth"
                :boxHeight="position.boxHeight"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- <div v-else>
      <p>ยังไม่ได้อัพโหลดรูปภาพ</p>
    </div> -->
    <!-- <pre>{{ JSON.stringify(globalData, null, 2) }}</pre> -->
    <!-- <pre>{{ JSON.stringify(positionList, null, 2) }}</pre> -->
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import EditorMarker from './Editor-Marker.vue'

export default {
  components: { EditorMarker },
  name: 'editor',
  props: {
    imgUrl: String,
    options: Array,
    defaultValue: Array,
    draftPositionList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      positionList: [],
      globalData: {
        originalWidth: 0,
        originalHeight: 0,
        clientWidth: 0,
        clientHeight: 0,
        currentActive: null,
      },
    }
  },
  computed: {},
  methods: {
    ...mapMutations('wallpapers', ['UPDATE_MAIN_POSITION_LIST']),
    onImageLoad(e) {
      const { naturalWidth, naturalHeight, clientHeight, clientWidth } = e.target
      this.globalData.originalHeight = naturalHeight
      this.globalData.originalWidth = naturalWidth
      this.globalData.clientHeight = clientHeight
      this.globalData.clientWidth = clientWidth

      this.positionList.map((value, index) => {
        if (typeof value !== 'object') return

        if (value.positionX) {
          const newMarkerLeft = (Number(value.positionX) / naturalWidth) * clientWidth
          this.positionList[index].positionX = value.positionX
          this.positionList[index].markerLeft = newMarkerLeft
        }

        if (value.positionY) {
          const newMarkerHeight = (Number(value.positionY) / naturalHeight) * clientHeight
          this.positionList[index].positionY = value.positionY
          this.positionList[index].markerTop = newMarkerHeight
        }

        this.positionList[index].offsetBoxWidth = Number(value.boxWidthCalculate ?? 100)
        this.positionList[index].boxWidth = (Number(value.offsetBoxWidth) / naturalWidth) * clientWidth

        this.positionList[index].offsetBoxHeight = Number(value.boxHeightCalculate ?? 50)
        this.positionList[index].boxHeight = (Number(value.offsetBoxHeight) / naturalHeight) * clientHeight
      })
    },
    getDimensionResizeImg() {
      if (!this.imgUrl) return
      this.globalData.clientHeight = this.$refs.editorImage?.clientHeight
      this.globalData.clientWidth = this.$refs.editorImage?.clientWidth
    },
    calculatePosition(e) {
      if (this.globalData.currentActive === null) return

      const currentIndex = this.globalData.currentActive
      const currentPositionData = this.positionList[currentIndex]
      if (e.offsetX > this.globalData.originalWidth) {
        currentPositionData.offsetX = this.globalData.originalWidth
      } else if (e.offsetX < 0) {
        currentPositionData.offsetX = 0
      } else {
        currentPositionData.offsetX = Number(e.offsetX).toFixed(2)
      }
      if (e.offsetY > this.globalData.originalHeight) {
        currentPositionData.offsetY = this.globalData.originalHeight
      } else if (e.offsetY < 0) {
        currentPositionData.offsetY = 0
      } else {
        currentPositionData.offsetY = Number(e.offsetY).toFixed(2)
      }

      currentPositionData.positionX = Number(
        (e.offsetX / this.globalData.clientWidth) * this.globalData.originalWidth,
      ).toFixed(2)

      currentPositionData.positionY = Number(
        (e.offsetY / this.globalData.clientHeight) * this.globalData.originalHeight,
      ).toFixed(2)

      currentPositionData.markerLeft = e.offsetX || 0
      currentPositionData.markerTop = e.offsetY || 0

      this.positionList[currentIndex] = currentPositionData
    },
    savePosition() {
      this.globalData.currentActive = null
    },
    onChangeInputPosition(e, data) {
      const { name, value } = e.target
      const currentDataIndex = this.positionList.findIndex(item => item.key === data.key)

      if (name === 'positionX') {
        // cal x
        if (!value || value < 0) {
          this.positionList[currentDataIndex].positionX = 0
          this.positionList[currentDataIndex].markerLeft = 0
          return
        }
        if (value > this.globalData.originalWidth) {
          this.positionList[currentDataIndex].positionX = this.globalData.originalWidth
          this.positionList[currentDataIndex].markerLeft = this.globalData.clientWidth
          return
        }

        this.positionList[currentDataIndex].positionX = Number(value)
        this.positionList[currentDataIndex].markerLeft =
          (Number(value) / this.globalData.originalWidth) * this.globalData.clientWidth
      } else {
        // cal y
        if (!value || value < 0) {
          this.positionList[currentDataIndex].positionY = 0
          this.positionList[currentDataIndex].markerTop = 0
          return
        }
        if (value > this.globalData.originalHeight) {
          this.positionList[currentDataIndex].positionY = this.globalData.originalHeight
          this.positionList[currentDataIndex].markerTop = this.globalData.clientHeight
          return
        }
        this.positionList[currentDataIndex].positionY = Number(value)
        this.positionList[currentDataIndex].markerTop =
          (Number(value) / this.globalData.originalHeight) * this.globalData.clientHeight
      }
    },
    onClickEdit(position) {
      if (this.globalData.currentActive || this.globalData.currentActive === 0) {
        this.globalData.currentActive = null
        return
      }

      const dataIndex = this.positionList.findIndex(item => item.key === position.key)
      this.globalData.currentActive = dataIndex
    },
    getPositions() {
      const newPositionList = this.positionList.map(item => {
        // const boxWidthCalculate = item.offsetBoxWidth
        // const boxHeightCalculate = item.offsetBoxHeight

        return { ...item, boxWidthCalculate: item.offsetBoxWidth, boxHeightCalculate: item.offsetBoxHeight }
      })

      const data = {
        ...this.globalData,
        positions: [...newPositionList],
      }
      // this.$emit("onSave", data);
      return data
    },
    onChangeInputBox(e, data) {
      const { name, value } = e.target

      const currentDataIndex = this.positionList.findIndex(item => item.key === data.key)

      if (currentDataIndex.length <= 0) return

      if (name === 'boxWidth') {
        if (!value || value <= 0) {
          this.positionList[currentDataIndex].boxWidth = 0
          this.positionList[currentDataIndex].offsetBoxWidth = 0
          this.positionList[currentDataIndex].boxWidthCalculate = 0
          return
        }
        if (value > this.globalData.originalWidth) {
          this.positionList[currentDataIndex].boxWidth = this.globalData.clientWidth
          this.positionList[currentDataIndex].offsetBoxWidth = this.globalData.originalWidth
          this.positionList[currentDataIndex].boxWidthCalculate = this.globalData.originalWidth

          return
        }
        const boxWidthCalculate = (Number(value) / this.globalData.originalWidth) * this.globalData.clientWidth
        this.positionList[currentDataIndex].boxWidth = boxWidthCalculate
        this.positionList[currentDataIndex].offsetBoxWidth = Number(value)
        this.positionList[currentDataIndex].boxWidthCalculate = Number(value)
      } else {
        if (!value || value <= 0) {
          this.positionList[currentDataIndex].boxHeight = 0
          this.positionList[currentDataIndex].offsetBoxHeight = 0
          this.positionList[currentDataIndex].boxHeightCalculate = 0

          return
        }
        if (value > this.globalData.originalHeight) {
          this.positionList[currentDataIndex].boxHeight = this.globalData.clientHeight
          this.positionList[currentDataIndex].offsetBoxHeight = this.globalData.originalHeight
          this.positionList[currentDataIndex].boxHeightCalculate = this.globalData.originalHeight

          return
        }
        const boxHeightCalculate = (Number(value) / this.globalData.originalHeight) * this.globalData.clientHeight
        this.positionList[currentDataIndex].boxHeight = boxHeightCalculate
        this.positionList[currentDataIndex].offsetBoxHeight = Number(value)
        this.positionList[currentDataIndex].boxHeightCalculate = Number(value)
      }
    },
    renderTextEdit(index) {
      if (this.globalData.currentActive === index) {
        return 'บันทึก'
      }
      return 'แก้ไข'
    },
  },
  watch: {
    // eslint-disable-next-line func-names, space-before-function-paren
    'globalData.clientWidth': function(newValue, oldValue) {
      if (!oldValue) return
      const p = oldValue / newValue
      const newList = this.positionList.map(item => {
        const markerLeft = item.markerLeft / p
        const boxWidth = item.boxWidth / p
        return { ...item, markerLeft, boxWidth }
      })
      this.positionList = newList
    },
    // eslint-disable-next-line func-names, space-before-function-paren
    'globalData.clientHeight': function(newValue, oldValue) {
      if (!oldValue) return
      const p = oldValue / newValue
      const newList = this.positionList.map(item => {
        const markerTop = item.markerTop / p
        const boxHeight = item.boxHeight / p
        return { ...item, markerTop, boxHeight }
      })
      this.positionList = newList
    },
  },
  mounted() {
    window.addEventListener('resize', this.getDimensionResizeImg)

    this.options.forEach((val, index) => {
      const oldData = this.defaultValue.filter(item => item.key === val.key)
      const draftData = this.draftPositionList.filter(item => item.key === val.key)

      if ([...draftData].length > 0) {
        this.positionList.push(...draftData)
      } else if ([...oldData].length > 0) {
        this.positionList.push(...oldData)
      } else {
        const obj = {
          key: val.key,
          hint: val.hint,
          markerTop: 0,
          markerLeft: 0,
          offsetX: 0,
          offsetY: 0,
          positionX: 0,
          positionY: 0,
          boxWidth: 0,
          boxHeight: 0,
          offsetBoxWidth: 0,
          offsetBoxHeight: 0,
        }
        this.positionList.push(obj)
      }
    })
  },
  beforeDestroy() {
    this.UPDATE_MAIN_POSITION_LIST(this.positionList)
    window.removeEventListener('resize', this.getDimensionResizeImg)
  },
}
</script>

<style lang="scss" scoped>
.editor-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.container-input-editor {
  margin: 20px 0;
  .group-input {
    margin-left: 20px;
    .custom-btn {
      margin-top: 5px;
    }
  }
}
.box {
  width: 100%;
  margin: 10px auto;
  // overflow: hidden;
  max-width: 500px;
  position: relative;
}

.box .image {
  width: 100%;
  height: auto;
  outline: 1px solid black;
}

.dot {
  width: 1px;
  height: 1px;
  background-color: transparent;
  position: absolute;
}

.dot .hint {
  width: max-content;
  background-color: white;
  font-size: 0.6rem;
  position: absolute;
  height: auto;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  left: 2px;
  top: 2px;
}

.btn-unlock {
  display: block;
  margin: 0 auto;
}

.marker-icon {
  width: 20px;
  position: absolute;
  color: green;
  top: -20px;
  left: -10px;
}

.empty-img {
  background-color: whitesmoke;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}

.container-img-editor {
  background-color: whitesmoke;
  padding: 1rem;
  border-radius: 0.5rem;
}

.container-form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.form-position {
  display: flex;
  flex-direction: column;
  p {
    padding: 0;
    margin-bottom: 0.5rem;
  }
  input {
    padding: 0.5rem 0.5rem;
  }
}
</style>
