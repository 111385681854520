<template>
  <div
    class="marker"
    :style="{
      top: `${markerTop}px`,
      left: `${markerLeft}px`,
      width: `${boxWidth}px`,
      height: `${boxHeight}px`,
    }"
  >
    <div class="hint">{{ hint }}</div>
    <svg xmlns="http://www.w3.org/2000/svg" class="marker-icon" viewBox="0 0 20 20" fill="currentColor">
      <path
        fill-rule="evenodd"
        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
        clip-rule="evenodd"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'editor-marker',
  props: {
    markerTop: Number,
    markerLeft: Number,
    hint: String,
    boxWidth: Number,
    boxHeight: Number,
  },
  data() {
    return {}
  },
}
</script>

<style lang="css" scoped>
.marker {
  width: 1px;
  height: 1px;
  background-color: transparent;
  position: absolute;
  border: 1px dashed rgb(231, 107, 107);
}

.marker-icon {
  width: 20px;
  position: absolute;
  color: green;
  top: -20px;
  left: -10px;
}

.marker .hint {
  width: max-content;
  background-color: white;
  font-size: 0.6rem;
  position: absolute;
  height: auto;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  left: 10px;
  top: -28px;
}
</style>
